@import 'src/assets/styles/variables';

.auth {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgb(0, 109, 119) 0%, rgb(0, 109, 119) 50vh, rgb(229, 229, 229) 50vh, rgb(229, 229, 229) 100%);
  @media only screen and (max-width: 670px) {
    padding: 20px;
    width: 100%;
    background-image: url("~/public/static/img/space-discovery.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh; }
  @media screen and (orientation: landscape) and (max-width: 1023px) {
    height: auto; }
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    max-width: 824px;
    background: #E5F0F1;
    border-radius: 10px;
    @media only screen and (max-width: 670px) {
      margin: 60px 0; } }
  &-image {
    background-image: url("~/public/static/img/space-discovery.svg");
    background-repeat: no-repeat;
    background-size: auto;
    width: 50%;
    height: 0;
    padding-top: 77.7%;
    position: relative;
    top: 0;
    @media only screen and (max-width: 670px) {
      display: none; }
    img {
      margin: 24px 0 0 24px;
      position: absolute;
      top: 0;
      @media only screen and (max-width: 670px) {
        width: 80px;
        height: 20px; } } }
  &-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24.5px; }
  &-form {
    width: 50%;
    padding: 32px;
    height: 640.24px;
    position: relative;
    background: #FFFFFF;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    @media only screen and (max-width: 670px) {
      padding: 20px;
      width: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    @media only screen and (max-height: 640px) {
      height: 540.24px; }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #002B2F;
      @media only screen and (max-width: 768px) {
        font-size: 18px; } }
    p {
      color: #A4A4A4;
      line-height: 21px; }
    form {
      margin-top: 32px; }
    .error {
      color: $error-color;
      max-width: 400px;
      font-size: 11px;
      line-height: normal;
      margin: 4px 0 0 5px; }
    .global-error {
      margin-top: 20px;
      font-size: 14px; }
    .form-row {
      label {
        margin: 0 0 9px 9px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px; }
      .label-error {
        color: $error-color; }
      .input-block {
        position: relative;
        .prefix {
          position: absolute;
          z-index: 1;
          width: 13.64px;
          height: 13.65px;
          background-image: url('~/public/static/img/people-focus.svg');
          background-repeat: no-repeat;
          left: 17.17px;
          opacity: 0.5;
          top: 50%;
          transform: translateY(-50%); }
        .prefix.password {
          background-image: url('~/public/static/img/password-focus.svg');
          width: 12.67px;
          height: 12.67px; }
        .prefix.email {
          background-image: url('~/public/static/img/email-focus.svg');
          width: 12.67px;
          height: 10.33px; }
        .password-icon {
          position: absolute;
          right: 7.17px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          padding: 10px; } }
      .form-input {
        padding: 12.5px 10px 12.5px 38px; } }
    .form-input:focus + .prefix, .input-block:focus .prefix,
    .form-input:focus + .prefix.password, .input-block:focus .prefix.password,
    .form-input:focus + .prefix.email, .input-block:focus .prefix.email {
      opacity: 1; }
    .description {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #002B2F;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 300px;
      a {
        margin-left: 4px;
        text-decoration: underline; } }
    a {
      color: #006D77;
      font-size: 12px;
      text-decoration: none; } }
  &-line {
    font-family: sans-serif;
    margin: 32.5px auto;
    color: #B8B8B8;
    text-align: center;
    font-size: 14px;
    max-width: 600px;
    position: relative;
    &:before, &:after {
      content: "";
      display: block;
      width: 150.5px;
      height: 0.5px;
      background: #BECCDF;
      position: absolute;
      top: 50%;
      @media only screen and (max-width: 992px) {
        width: 140.5px; }
      @media only screen and (max-width: 450px) {
        width: 125.5px; }
      @media only screen and (max-width: 450px) {
        width: 100.5px; } }
    &:before {
      left: 0; }
    &:after {
      right: 0; } }
  &-intro {
    text-align: center;
    padding: 90px 0 120px;
    @media only screen and (max-width: 1300px) {
      padding: 90px 3% 120px; }
    @media only screen and (max-width: 768px) {
      padding: 90px 20px 90px 20px; }
    h1 {
      font-size: 60px;
      line-height: 80px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #002B2F;
      font-weight: 400;
      margin: 0;
      @media only screen and (max-width: 991px) {
        font-size: 45px;
        line-height: 48px; } }
    p {
      font-size: 20px;
      line-height: 151.9%;
      text-align: center;
      color: #002B2F;
      font-weight: 400;
      padding: 30px 0;
      @media only screen and (max-width: 991px) {
        padding: 10px 0;
        font-size: 16px; } } }

  &-primary-button {
    margin: 32.5px auto 24px auto;
    background: #F8F8F8;
    border-radius: 10px;
    width: 100%;
    border: none;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 17px;
    color: #002B2F;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #FCFCFC; }
    &:focus {
      background: #F4F4F4; }
    &:active {
      background: lighten(#006D77, 5); } }
  &-social {
    width: 100%;
    border: none;
    margin: 32.5px auto 24px auto;
    background: #F8F8F8;
    padding: 10px 16px;
    font-size: 14px;
    justify-content: center;
    font-weight: 500;
    line-height: 22px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #002B2F;
    img {
      margin-right: 8px; }
    &:hover {
      background: #FCFCFC; }
    &:focus {
      background: #F4F4F4; }
    &:active {
      background: #F4F4F4; } } }

.btn-auth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25.5px;
  img {
    margin-right: 9.33px; }
  &:disabled {
    img {
      opacity: 0.5; } } }

.not-found {
  background: rgb(229, 229, 229);
  padding: 0; }

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #FFFFFF;
  h1 {
    @media only screen and (max-width: 991px) {
      font-size: 30px;
      line-height: 50px; } }
  .logo {
    margin-top: 50px; }
  button {
    margin: 0 auto; } }

.checkbox-container {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; } }
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #E6F1F2;
  border-radius: 2.66667px; }
.checkbox-container {
  &:hover input ~ .checkmark {
    background-color: #E6F1F2; }
  input:checked ~ .checkmark {
    background-color: #006D77; }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none; } }

.checkbox-container {
  input:checked ~ .checkmark:after {
    display: block; } }

.checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg); }

.verify-email-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19.11px 0 14.9px 0;
  background: #E5F0F1;
  border-radius: 10px;
  margin: 32px 0; }

.forgot-password {
  .global-error {
    margin-top: 25px; } }

@import 'src/assets/styles/variables';

.dashboard-layout {
  .modal-content {
    background: $light-color;
    .modal-header {
      margin-bottom: 20px;
      img {
        padding: 10px; } }
    .stepper-footer {
      margin-top: 48px; } }
  .modal-content.modal-small {
    max-width: 435px; }
  .modal-content.language.true {
    overflow: scroll; }
  .modal-content.language {
    overflow: inherit; }
  .modal-content.modal-base,.modal-content.modal-small {
    h3 {
      color: $accent-color;
      font-size: 20px; } }
  .modal-content.success {
    text-align: center;
    padding: 80px;
    @media screen and (max-width: 768px) {
      padding: 30px; }
    @media screen and (max-width: 992px) {
      width: 92%; }
    h3 {
      font-size: 25px;
      margin-bottom: 15px; }
    p {
      font-size: 20px;
      line-height: 30px; }
    img {
      margin-bottom: 40px; }
    .form-btn-block {
      margin-top: 30px;
      button {
        margin: 0 20px; } }
    @media screen and (max-width: 768px) {
      img {
        width: 70px; }
      h3 {
        font-size: 20px; }
      p {
        font-size: 16px;
        line-height: 20px; }
      br {
        display: none; } } }
  footer {
    background-color: #006d77; } }

.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 1025px;
  margin: 0 auto;
  padding: 26px 0 26px 0;
  @media screen and (max-width: 768px) {
    padding: 26px 0 20px 0; }
  .link {
    list-style: none;
    button, a {
      padding: 10px;
      margin: 0 10px;
      text-decoration: none;
      cursor: pointer;
      color: $light-color;
      font-weight: bold;
      font-size: 15px;
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-family: $global-font;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        padding: 5px;
        margin: 0 8px; }
      span {
        display: none;
        @media screen and (min-width: 768px) {
          display: block; } }
      img {
        margin-left: 9px; } } }
  .link.mobile-icon {
    button {
      transition: all .2s ease-in-out;
      img, .saving-mobile {
        display: none;
        @media screen and (max-width: 767px) {
          display: block;
          margin-left: 0; } }
      @media screen and (max-width: 767px) {
        &:active {
          transform: scale(1.1);
          transition: .1s; } } } } }

.user-form {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 824px;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 90px 80px;
  background: #FFFFFF;
  margin: 114px auto;
  border-radius: 10px;
  @media screen and (max-width: 992px) {
    padding: 50px 40px;
    margin: 90px auto 80px auto;
    max-width: 750px; }
  @media screen and (max-width: 768px) {
    margin: 50px auto 40px auto;
    padding: 50px 20px;
    max-width: 688px; }
  @media screen and (max-width: 576px) {
    max-width: 500px;
    margin: 40px auto; }
  @media screen and (max-width: 450px) {
    max-width: 400px; }
  @media screen and (max-width: 430px) {
      max-width: 410px;
      padding: 40px 20px 35px 20px; }
  @media screen and (max-width: 420px) {
      max-width: 365px; }
  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: block;
    margin-bottom: 10px; }
  .asterisk {
    color: $error-color; }
  .form-section-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin: 13px 0 21px 0;
    @media screen and (max-width: 768px) {
      font-size: 16px; } }
  &__content {
    width: 100%; }
  .block-two-row.center {
    align-items: center; }
  .block-two-row {
    display: flex;
    > div {
      &:first-child {
        margin-right: 20px;
        @media screen and (max-width: 768px) {
         margin-bottom: 20px;
         margin-right: 0; } } } }
  .block-two-row.mobile {
    @media screen and (max-width: 768px) {
      display: block; }
    > div {
      width: 100%; } }
  .block-two-row.mobile-select {
    @media screen and (max-width: 768px) {
      flex-direction: column; }
    > div {
      width: 100%;
      &:nth-child(3), &:last-child {
        margin-bottom: 0; } } }
  .avatar-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .pre-img {
      width: 246px;
      height: 246px;
      border: 1px dashed #BECCDF;
      border-radius: 50%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      pointer-events: none;
      @media screen and (max-width: 767px) {
        width: 200px;
        height: 200px; }
      > img {
        width: 246px;
        height: 246px;
        object-fit: cover;
        @media screen and (max-width: 767px) {
          width: 200px;
          height: 200px; } }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(241, 244, 248, 0.9), rgba(241, 244, 248, 0.9));
        border-radius: 50%; } }
    input[type=file] {
      display: none; }
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 246px;
      height: 246px;
      background: #FFFFFF;
      cursor: pointer;
      border-radius: 50%;
      p {
        position: absolute;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #006D77; } } }
  .border-line-bottom {
    height: 0.5px;
    background: #BECCDF;
    margin: 24px 0 24px 0;
    border: none; }
  .characters-left {
    position: relative;
    display: flex;
    justify-content: flex-end;
    color: $dark-grey-color;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    margin: 5px 5px 0 0;
    .error-message {
      margin-top: 3px;
      left: 5px; }
    span {
      white-space: nowrap;
      color: #002B2F; } }
  .form-btn-block {
    display: flex;
    justify-content: center;
    margin-top: 40px; }

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    background-color: $input-color;
    color: $text-field-color;
    border-radius: 10px!important;
    border: 0.5px solid $border-field-color;
    outline: none; }

  .css-1pahdxg-control {
    &:hover {
      border: 0.5px solid $border-field-color; } }

  .css-319lph-ValueContainer {
    @media screen and (max-width: 768px) {
      padding: 4px 8px; } }

  .css-ackcql {
    @media screen and (max-width: 768px) {
      margin: 7px 8px!important; } }

  .css-b62m3t-container {
    width: 100%;
    &:focus-visible {
      outline: none; } }

  .css-6j8wv5-Input {
    input::placeholder {
      font-family: $global-font;
      color: $placeholder-color;
      font-size: 14px;
      line-height: 15px; } }

  .css-14el2xx-placeholder {
    color: $placeholder-color;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }

  .css-1okebmr-indicatorSeparator {
    display: none; }

  .css-26l3qy-menu {
    background-color: $input-color;
    font-size: 12px; }

  .css-qc6sy-singleValue {
    overflow: visible; }

  .css-4ljt47-MenuList {
    background-color: $input-color; }

  .css-1n7v3ny-option {
    background-color: #EAEEF2; }

  .css-9gakcf-option {
    background-color: #EAEEF2;
    color: $dark-color;
    &:active {
      background-color: #EAEEF2;
      color: $dark-color; } }

  .css-yt9ioa-option {
    background-color: #FFFFFF; }

  .css-6j8wv5-Input {
    padding: 5px 0; }

  .css-tj5bde-Svg {
    color: #006D77;
    width: 14px;
    height: 14px; }

  .social {
    &__item {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;
      color: $accent-color;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        margin-left: 7.86px; } } }

  .preview-image-block {
    position: relative; } }

.lang {
  &-block {
    display: flex;
    justify-content: space-between;
    margin: 20px 0; }
  &-data {
    display: flex; }
  &-level {
    margin-left: 5px;
    font-weight: 600;
    color: $accent-color; }
  &-btn-block {
    display: flex;
    button {
      display: block; }
    .edit {
      margin-right: 20px; } } }

.form-radio {
  margin: 26px 0;
  label {
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    user-select: none;
    &:hover {
      &:before {
        filter: brightness(120%); } }
    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      background: url("~/public/static/img/input.svg") 0 0 no-repeat; } }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 2px 0 10px 10px;
    display: inline-block; }
  input[type=radio] {
    display: none; }
  input[type=radio]:checked + label:before {
    background: url("~/public/static/img/radio.svg") 0 0 no-repeat; }
  input[type=radio]:disabled + label:before {
    filter: grayscale(100%); } }

.preview-image-block, .project-img-block {
  margin: 5px 16px 10px 0;
  @media screen and (max-width: 430px) {
    margin: 5px 10px 10px 0; } }

.preview-image {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 200px;
  &-delete {
    box-shadow: 5px 5px 18px rgba(63, 65, 67, 0.5);
    background: white;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    z-index: 1; } }
.form-btn-block.align-end {
  justify-content: flex-end;
  .btn-transparent {
    margin-right: 20px; } }

.steper {
  display: flex;
  flex: 1 1 auto;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  width: 100%;
  &__item {
    flex: 1;
    position: relative; }
  &__item-box {
    flex-direction: column;
    align-items: center;
    display: flex; }
  &__circle {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid #B8B8B8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px; }
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px; }
  .active {
    h4 {
      color: $accent-color; } } }

.form-row {
  margin-bottom: 24px;
  line-height: 0;
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
    display: inline-block;
    @media screen and (max-width: 768px) {
      font-size: 16px; } }
  &.social-network-input {
    position: relative;
    transition: 0.5s all;
    .prefix, .prefix-icon {
      position: absolute;
      bottom: 50%;
      height: 15px;
      left: 10px;
      display: flex;
      align-items: center;
      padding-bottom: 3px;
      color: $dark-color; }
    .prefix-icon {
      height: 13px;
      svg {
        width: 14px;
        height: 14px; } }
    .form-input {
      padding: 12.5px 10px 12.5px 28px;
      @media screen and (max-width: 768px) {
        padding: 10px 10px 10px 23px; } } } }

.social {
  &-btn {
    transition: 0.5s all;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0;
    opacity: 1;
    svg {
      pointer-events: none; } }
  &-nickname {
    margin: 24px 0 9px 0; }
  &-icons {
    display: flex;
    flex-flow: row wrap;
    button {
      margin: 4px 15px 20px 0;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(20% - 15px); } } }

.social-btn {
  opacity: 0.2;
  &:hover {
    opacity: 1; } }

.social-btn.inactive {
  opacity: 0.2; }

.social-btn.active {
  opacity: 1; }

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0; }

.social-network-block {
  display: flex;
  flex-flow: row wrap;
  @media screen and (max-width: 768px) {
      display: block; } }

.social-network {
  position: relative;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 50%;
  .form-input, .characters-left {
    width: 90%;
    @media screen and (max-width: 768px) {
      width: 92%; } }
  .social-network-delete {
    margin-bottom: 20px;
    padding: 0 5px 0 10px;
    position: absolute;
    left: 88%;
    top: 10px;
    @media screen and (max-width: 768px) {
      left: 92%; } }
  .form-input {
    padding: 12.5px 10px 12.5px 50px!important; }
  .prefix {
    left: 36px!important; } }

.form-input, .react-tel-input .form-control, .form-input,
.form-textarea, .css-1s2u09g-control, .css-1pahdxg-control, .css-26l3qy-menu {
  color: $text-field-color;
  background: $input-color;
  font-family: $global-font;
  border: 0.5px solid $border-field-color;
  border-radius: 10px;
  line-height: 17px;
  font-size: 14px;
  font-weight: normal;
  @media screen and (max-width: 768px) {
    font-size: 16px!important; }
  &:focus {
    border: 1px solid #002B2F; } }


.form-input, .react-tel-input .form-control {
  padding: 12.5px 10px;
  outline: none;
  display: flex;
  width: 100%;
  height: 40px;
  text-overflow: ellipsis;
  &:focus {
    border: 1px solid #002B2F; }
  @media screen and (max-width: 768px) {
    padding: 10px; } }

.error-border {
  border: 1px solid $error-color; }

.react-tel-input .form-control {
  border: 0.5px solid $border-field-color!important;
  border-radius: 10px!important;
  outline: none!important;
  height: 40px!important;
  font-size: 14px!important;
  padding: 12.5px 10px 12.5px 40px!important;
  &:focus {
    border: 1px solid #002B2F!important;
    background: $input-color!important;
    box-shadow: 0 0 0 0 transparent!important; }
  @media screen and (max-width: 768px) {
    padding: 10px 10px 10px 55px!important; } }

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: 0 0 0 0 transparent!important;
  border-color: transparent!important; }

.react-tel-input {
  .arrow {
    display: none; } }

.react-tel-input .country-list .country.highlight {
  background-color: #F1F4F8!important; }

.form-textarea {
  width: 100%;
  height: 120px;
  padding: 12px 10px;
  resize: none;
  outline: none;
  line-height: 21px;
  &:focus {
    border: 1px solid #002B2F; } }

.form-textarea::placeholder {
  color: $placeholder-color;
  font-family: $global-font;
  font-size: 14px; }

.form-input::placeholder, input::placeholder, select::placeholder {
  color: $placeholder-color;
  font-size: 14px; }

.add-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: $accent-color;
  text-align: right;
  padding: 0 14px 10px 0;
  span {
    padding-right: 5px;
    font-size: 16px; }
  &:disabled {
    color: $grey-color;
    cursor: inherit; }
  @media screen and (max-width: 768px) {
    font-size: 16px; } }

.react-tags-block {
  position: relative;
  button {
    position: absolute;
    padding: 8px;
    margin: 0;
    right: 0;
    height: 40px;
    width: 48px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .characters-left {
    position: absolute;
    top: -22px;
    right: 0; } }

.react-tags {
  background: $input-color;
  border-radius: 10px;
  border: 0.5px solid $border-field-color;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  padding: 12.5px 10px;
  position: relative;
  cursor: text;
  width: 100%;
  height: 40px;
  @media screen and (max-width: 768px) {
    padding: 10px 150px 10px 10px; }
  @media screen and (max-width: 600px) {
    padding: 10px 130px 10px 10px; } }

.react-tags__suggestions {
  display: block;
  width: 240px;
  ul {
    margin: 0;
    padding: 0;
    background: $light-color;
    li {
      list-style: none;
      padding: 8px;
      cursor: pointer;
      border-bottom: 0.5px solid #BECCDF; } } }

.react-datepicker {
  &__header {
    background-color: $accent-color!important; }
  &__current-month, &__day-name {
    color: $light-color!important; }
  &__day--selected {
    background-color: $accent-color!important; } }

.react-tags.is-focused {
  border: 1px solid #002B2F; }

.react-tags__selected {
  display: inline; }

.react-tags__selected-tag {
  display: inline-block;
  margin: 0 6px 2px 0;
  border: 0.5px solid $border-field-color;
  border-radius: 10px;
  background: $accent-color;
  color: $light-color;
  padding: 6px 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px; }

.react-tags__selected-tag:after {
  content: '\2715';
  color: $light-color;
  margin-left: 8px; }

.react-tags__search {
  display: inline-block;
  margin-bottom: 0;
  max-width: 100%;
  @media screen and (min-width: 30em) {
    position: relative; } }

.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: $input-color;
  width: 150px!important; }

.react-tags__search-input::-ms-clear {
  display: none; }

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; }

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  border: none;
  border-radius: 2px; }

.react-tags__suggestions li {
  border: none; }

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: $input-color; }

.react-tags__suggestions li.is-active {
  background: $input-color; }

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto; }

.error-message {
  display: inline-block;
  font-size: 10px;
  color: $error-color;
  margin: 8px 0 0 5px;
  white-space: pre-line;
  text-align: center; }

.error-message-img {
  bottom: -20px;
  position: absolute; }

.card {
  display: flex;
  justify-content: space-between;
  background: #F8FAFC;
  padding: 16px;
  border-radius: 10px;
  img {
    max-width: 164px; }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $accent-color;
    margin-bottom: 12px; }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px; }
  .date-block {
    display: block;
    font-size: 14px;
    margin-bottom: 12px;
    color: #A6ABB0;
    font-weight: 500; }
  .left-block {
    display: flex;
    @media screen and (max-width: 650px) {
      flex-direction: column; } }
  &__img-block {
    display: flex;
    margin-right: 16px;
    img {
      width: 164px;
      height: 128px;
      border-radius: 10px;
      object-fit: cover;
      @media screen and (max-width: 650px) {
        margin-bottom: 10px; } }
    @media screen and (max-width: 600px) {
      margin: 0; } }
  &__icon-block {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 10px; }
    img {
      display: flex;
      margin: 0; } }
  .title-block {
    display: flex;
    span {
      line-height: 10px;
      font-size: 14px; } }
  .institution {
    margin-top: 12px; }
  .location {
    display: flex;
    align-items: center;
    margin: 12px 0;
    font-size: 14px; }
  .block-buttons {
    width: 50px;
    justify-content: space-between; } }

.cart-flex-between {
  justify-content: space-between; }

.flex-row {
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: row; }
  .title-block {
    margin-bottom: 0; }
  .date-block {
    font-size: 12px;
    margin-top: 15px;
    color: #A6ABB0;
    font-weight: 500; }
  .card__icon-block {
    flex-direction: row;
    justify-content: inherit;
    align-items: center;
    button:first-of-type {
      margin: 0 8px 0 0; } } }

.certifications {
  .card {
    h3 {
      margin: 0; } } }

.skills-or-lang {
    .css-9gakcf-option {
      background-color: $input-color;
      &:hover {
        background-color: #EAEEF2;
        color: #002b2f; } } }

.portfolio {
  .card {
    h3 {
      margin: 10px 0 12px 0; } } }


.upload-project-img {
  display: none; }

.form-row label.upload-file {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 153px;
  margin-bottom: 0;
  background-color: #F1F4F8;
  padding: 0 30px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BECCDFFF' stroke-width='1' stroke-dasharray='5' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
  div {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px; } }

.upload-project-img-block {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  .img {
    max-width: 200px;
    height: 153px;
    object-fit: cover; }
  .form-row {
    margin: 0; }
  textarea {
    margin-top: 10px; }
  .project-img-block {
    width: 200px; }
  .error-message {
    bottom: 0; } }

.form-textarea.form-textarea-image-desc {
  height: 40px;
  padding: 12px;
  @media screen and (max-width: 768px) {
    max-width: 190px; }
  @media screen and (max-width: 660px) {
    max-width: 158px; }
  @media screen and (max-width: 430px) {
    max-width: 144px; }
  @media screen and (max-width: 320px) {
    max-width: 200px; } }


.form-row label.upload-file, .upload-project-img-block .img, .preview-image, .project-img-block {
  @media screen and (max-width: 768px) {
    max-width: 190px;
    height: 135px; }
  @media screen and (max-width: 660px) {
    max-width: 158px;
    height: 130px; }
  @media screen and (max-width: 430px) {
    max-width: 144px;
    height: 110px; }
  @media screen and (max-width: 320px) {
    max-width: 200px; } }

.stepper-wrapper {
  .stepper-head {
    display: flex;
    position: relative;
    width: 100%;
    user-select: none;
    margin-bottom: 64px;
    @media screen and (max-width: 768px) {
      margin-bottom: 44px; }
    .stepper {
      &-step {
        position: relative;
        text-align: center;
        padding: 0.5rem;
        flex-basis: 100%;
        @media screen and (max-width: 580px) {
          padding: 0; }
        &.rightToLeft {
          &:after {
            left: -50%; } }
        &.is-complete {
          .stepper-indicator-info {
            border-color: #4caf50;
            background-color: #4caf50;
            cursor: pointer; }
          .stepper-label {
            color: $accent-color; } }
        &.is-active {
          .stepper-indicator-info {
            border-color: $accent-color;
            background-color: transparent;
            cursor: initial;
            color: $accent-color;
            font-weight: 500; }
          .stepper-label {
            color: $accent-color; } }
        &.is-warning {
          .stepper-indicator-info {
            border-color: #f1c40f;
            background-color: #f1c40f; }
          .stepper-label {
            color: #f1c40f; } }
        &.is-error {
          .stepper-indicator-info {
            border-color: #e95a4b;
            background-color: #e95a4b; }
          .stepper-label {
            color: #e95a4b; } }
        &:after {
          content: " ";
          position: absolute;
          left: 69%;
          top: 2rem;
          width: 60%;
          height: 1px;
          background-color: #B8B8B8;
          z-index: 1;
          @media screen and (max-width: 580px) {
            left: 73%;
            top: 1rem;
            width: 55%; } }
        &:last-child:after {
          display: none; } }
      &-indicator {
        position: relative;
        display: block;
        z-index: 2;
        &-info {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border: 1px solid #B8B8B8;
          border-radius: 50%;
          background-color: transparent;
          font-size: 1rem;
          line-height: 2.5rem;
          text-align: center;
          color: #B8B8B8;
          z-index: 2;
          font-weight: 500;
          @media screen and (max-width: 580px) {
            width: 2rem;
            height: 2rem; }
          .stepper-tick {
            width: 14px;
            fill: #ffffff; } } }
      &-label {
        position: relative;
        display: block;
        margin: 0.5rem 0;
        color: #B8B8B8;
        z-index: 2;
        font-style: normal;
        font-size: 10px;
        line-height: 12px;
        font-weight: 600;
        @media screen and (max-width: 580px) {
          font-size: 8px; } } } }
  .stepper-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 64px;
    position: relative;
    .error-message {
      top: -30px;
      right: 0; } } }

.stepper-wrapper {
  .stepper-head {
    .stepper-step.ready {
      .stepper-indicator-info {
        cursor: pointer; }
      .stepper-label {
        cursor: pointer; } } } }

.btn-continue {
  &:hover {
    background: #097e8d; } }

.mt-2 {
  margin-top: 10px; }

.date-picker {
  position: relative;
  img {
    position: absolute;
    right: 10px;
    z-index: 2;
    top: 17px; } }

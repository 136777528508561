$custom-easing: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$animation-duration: 1.7s;
$small-stroke: 105;
$large-stroke: 210;
$large-stroke-animate: 190;
$rotate: 25deg;
$rotate-origin: -32deg;

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-direction: column;
  h1 {
    margin-top: 20px; } }

.loading-spinner {
  display: block;
  margin: 0 auto;
  animation: loading-spinner-spin 2s linear infinite;
  will-change: transform; }

@keyframes loading-spinner-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loading-circle-large {
  stroke-dasharray: $large-stroke;
  stroke-dashoffset: $large-stroke;
  stroke-linecap: round;
  animation: loading-spinner-large $animation-duration $custom-easing infinite;
  transform: translateZ(0);
  transform-origin: center;
  will-change: stroke-dashoffset; }

@keyframes loading-spinner-large {
  0% {
    stroke-dashoffset: $large-stroke-animate;
    transform: scaleY(1); }
  49.99% {
    stroke-dashoffset: 0;
    transform: scaleY(1); }
  50% {
    stroke-dashoffset: 0;
    transform: scaleY(-1) rotate($rotate); }
  100% {
    stroke-dashoffset: $large-stroke-animate;
    transform: scaleY(-1) rotate($rotate-origin); } }

$dark-color: #002B2F;
$accent-color: rgb(0, 109, 119);
$light-color: rgb(252, 252, 252);
$grey-color: rgb(184, 184, 184);
$dark-grey-color: rgb(98, 98, 98);
$error-color: #D63333;

$input-color: #F1F4F8;
$border-field-color: #BECCDF;
$text-field-color: #002B2F;
$placeholder-color: #A6ABB0;

$bg-main: rgb(229, 229, 229);
$bg-footer: rgb(0, 82, 90);

$page-content-width: 960px;

$global-font: 'Montserrat', 'Helvetica Neue', 'Roboto', sans-serif;

@import "variables";

* {
  box-sizing: border-box; }

html {
  -webkit-text-size-adjust: 100%;
  position: relative;
  min-height: 100%; }

html,
body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $global-font;
  background: rgb(229, 229, 229);
  color: $dark-color;
  scroll-behavior: smooth; }

h1, h2, h3, h4, h5, h6, p, div, nav, section, footer {
  margin: 0;
  padding: 0; }

body.no-scroll {
  overflow: hidden;
  margin: 0;
  touch-action: none;
  -ms-touch-action: none;
  height: 100vh; }

#root {
  display: flex;
  flex-direction: column;
  height: 100%; }

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto; }

.dashboard-layout {
  background: linear-gradient(to bottom, rgb(0, 109, 119) 0%, rgb(0, 109, 119) 700px, rgb(229, 229, 229) 400px, rgb(229, 229, 229) 100%); }

.w-100 {
  width: 100%; }

.btn {
  background: #006D77;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FCFCFC;
  padding: 10px 16px;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    background: #80B6BB;
    color: rgba(252, 252, 252, 0.5);
    cursor: default;
    &:hover {
      background: #80B6BB; } }
  &:focus {
    background: #00575F; }
  &:hover {
    background: #328A92; } }

.btn-transparent {
  background: transparent;
  border: 1px solid #006D77;
  border-radius: 10px;
  color: #006D77;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  padding: 10px 16px;
  &:disabled {
    border-color: #80B6BB;
    color: #80B6BB;
    cursor: default;
    &:hover {
      border-color: #80B6BB;
      color: #80B6BB; } }
  &:focus {
    border-color: #00575F;
    color: #00575F; }
  &:hover {
    border-color: #328A92;
    color: #328A92; } }

.btn-click, .add-btn {
  transition: all .2s ease-in-out;
  &:active {
    transform: scale(1.1);
    transition: .1s; }
  &:disabled {
    transform: scale(1); } }

.btn-save {
  background: transparent;
  border: 1px solid $accent-color;
  color: $accent-color; }

.d-flex {
  display: flex; }

.align-baseline {
  align-items: baseline; }

.justify-between {
  justify-content: space-between; }

.mb-2 {
  margin-bottom: 20px; }

.mb-15 {
  margin-bottom: 15px; }

.primary-tag {
  background: #E6F1F2;
  line-height: initial;
  border-radius: 10px;
  color: $dark-color;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 16px;
  display: inline-block;
  margin: 0 20px 19px 0;
  &__content {
    display: flex;
    align-items: center; }
  &__icon {
    cursor: pointer;
    width: 10.11px;
    height: 12.58px;
    margin-left: 6.94px; }
  &.accent-color-tag {
    background: #E6F1F2;
    color: $accent-color;
    margin: 0 20px 10px 0;
    pointer-events: none;
    cursor: pointer;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 200px;
      white-space: nowrap; }
    @media screen and (max-width: 425px) {
      margin: 0 9px 10px 0; }
    &:after {
      content: '\2715';
      color: $accent-color;
      padding: 5px 5px 5px 9px;
      pointer-events: auto; } }

  &-long {
    display: inline-block; } }

.primary-tag.long {
  display: inline-flex;
  &:after {
    padding: 0 5px 0 8px; } }

input::-webkit-input-placeholder {
  @media screen and (max-width: 768px) {
    font-size: 14px; } }

.modal-overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: 1s all;
  backdrop-filter: blur(5px); }

.modal-wrap {
  position: fixed;
  transition: 0.5s all;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 728px;
    padding: 32px;
    height: auto;
    pointer-events: all;
    max-height: 95vh;
    overflow: auto;
    border-radius: 10px;
    .modal-header {
      align-items: center;
      justify-content: space-between;
      align-items: center;
      h3 {
        color: $accent-color;
        font-size: 20px; }
      img {
        cursor: pointer; } } }
  .modal-content.full-height {
    @media screen and (max-width: 767px) {
      border-radius: 0;
      max-height: 100vh;
      height: 100vh;
      background: #fcfcfc; } }
  .popup-contact {
    padding: 0;
    min-height: 0;
    border-radius: 10px;
    background: $accent-color;
    @media screen and (orientation: landscape) and (max-width: 991px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } } }

.error-message {
  opacity: 0;
  position: absolute; }

.error-message.error {
  opacity: 1; }

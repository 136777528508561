footer {
  background: #00525A;
  > div {
    display: flex;
    justify-content: space-between;
    padding: 42px 140px 42px 90px;
    @media only screen and (max-width: 1300px) {
      padding: 42px 7%; }
    @media only screen and (max-width: 768px) {
      padding: 40px 20px;
      text-align: center;
      display: block; }
    .main {
      display: flex;
      @media only screen and (max-width: 768px) {
        display: block; } }
    .links-menu {
      margin-left: 90px;
      @media only screen and (max-width: 768px) {
        margin: 20px 0 30px; }
      ul {
        list-style: none;
        padding: 0;
        margin: 5px 0 0 0;
        > li, a {
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.04em;
          color: #FCFCFC;
          margin-bottom: 10px;
          text-decoration: none; } } }
    .social-networks {
      ul {
        padding: 0;
        > li {
          display: inline-block;
          margin-left: 20px;
          &:first-child {
            margin: 0; } } }
      p {
        color: #FCFCFCFF;
        font-size: 12px;
        margin-top: 30px;
        text-align: right;
        @media only screen and (max-width: 768px) {
          text-align: center;
          margin-bottom: 0; } } } } }
